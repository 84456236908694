<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-body pb-10 pb-2">
                <div class="text-right">
                    <router-link to="/orders">
                        <b-button class="mr-2" size="md" type="reset" variant="secondary">
                            Cancelar y salir
                        </b-button>
                    </router-link>
                </div>
                <form-wizard
                        color="#3699ff"
                        nextButtonText="Siguiente"
                        backButtonText="Anterior"
                        finishButtonText="Aceptar y salir"
                        title="Solicitud de entrenamiento"
                        subtitle="Cursos para entrenamiento"
                        @on-complete="onComplete"
                        ref="wizard"
                        :start-index.sync="activeTabIndex"
                >
                    <tab-content
                            title="Ingreso de datos"
                            icon="ti-view-list-alt"
                            :before-change="() => stepOne()"
                    >
                        <new-order-form ref="newOrderFormRef"></new-order-form>
                    </tab-content>
                    <tab-content
                            title="Revisión de datos"
                            icon="ti-pencil-alt"
                            :before-change="() => stepTwo()"
                    >
                        <new-order-revision v-if="mountRevisionView"></new-order-revision>
                    </tab-content>
                    <tab-content title="Detalles" icon="ti-check">
                        <new-order-detail v-if="mountDetailsView"></new-order-detail>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import {FormWizard, TabContent} from "vue-form-wizard";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    //import {REQUESTS_GET_COMPANIES, REQUESTS_GET_EVALUATIONS, REQUESTS_SEND_FILE, REQUESTS_STORE_ORDER} from "@core/services/store/requests.module";
    import NewOrderForm from "./NewOrderForm";
    import NewOrderRevision from "./NewOrderRevision";
    import {
        ORDERS_SET_NEW_ORDER_DATA,
        ORDERS_STORE_ORDER,
        ORDERS_CHECK_DATA,
        ORDERS_VALIDATE_FILE,
        ORDERS_GET_TRAININGS_LIST,
        ORDERS_GET_ENTERPRISE_LIST,
        ORDERS_SET_NEW_ORDER_STUDENTS
    } from "@/store/modules/orders.module";
    import NewOrderDetail from "./NewOrderDetail";

    export default {
        name: "NewOrder",
        components: {
            NewOrderDetail,
            NewOrderRevision,
            //NewRequestVerification,
            NewOrderForm,
            FormWizard,
            TabContent,
        },
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 10,
                isLoading: false,
                errors: [],
                activeTabIndex: 0,
                errorsInStepOne: [],
                mountRevisionView: true,
                mountDetailsView: true,
                doNotCheckAgain: false
            };
        },
        mounted() {
            //this.$store.dispatch(REQUESTS_GET_COMPANIES);
            //this.$store.dispatch(REQUESTS_GET_EVALUATIONS);
            this.$store.dispatch(ORDERS_GET_TRAININGS_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(ORDERS_GET_ENTERPRISE_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Ordenes", route: "requests"},
                {title: "Nueva orden"},
            ]);
        },
        methods: {
            newOrderDataIsValid() {
                return this.newOrderData.training_id != null &&
                    this.newOrderData.enterprise_id != null &&
                    this.newOrderData.start_date != null &&
                    this.newOrderData.end_date != null &&
                    this.newOrderData.file != null
            },
            stepOne() {
                if (!this.newOrderDataIsValid()) return false
                if(this.doNotCheckAgain){
                    this.doNotCheckAgain = false
                    return false
                }

                let scope = this

                this.isLoading = true;

                let formData = new FormData();
                formData.append("training_id", this.newOrderData.training_id);
                formData.append("enterprise_id", this.newOrderData.enterprise_id);
                formData.append("start_date", this.newOrderData.start_date);
                formData.append("end_date", this.newOrderData.end_date);
                formData.append("welcome_message", this.newOrderData.welcome_message);
                formData.append("bye_message", this.newOrderData.bye_message);
                formData.append("file", this.newOrderData.file);
                formData.append("send_welcome_card", this.newOrderData.send_welcome_card);
                this.mountRevisionView = false
                this.$store
                    .dispatch(ORDERS_VALIDATE_FILE, formData)
                    .then((response) => {
                        if (response.data.status === false) {
                            if (response.data.go_back === false) {
                                scope.$bvToast.toast(
                                    JSON.stringify(response.data.errors),
                                    {
                                        title: response.data.message,
                                        variant: "warning",
                                        solid: true,
                                    }
                                );
                                scope.$store.commit(ORDERS_SET_NEW_ORDER_STUDENTS, response.data.students);
                                scope.mountRevisionView = true
                            } else {
                                scope.$refs.wizard.maxStep = 0
                                scope.$refs.wizard.tabs.forEach((tab) => {
                                    tab.checked = false
                                })
                                scope.$refs.wizard.navigateToTab(0)

                                scope.$bvToast.toast(
                                    JSON.stringify(response.data.errors),
                                    {
                                        title: response.data.message,
                                        variant: "danger",
                                        solid: true,
                                    }
                                );
                                scope.$store.commit(ORDERS_SET_NEW_ORDER_STUDENTS, null);
                            }
                        }else{
                            scope.$store.commit(ORDERS_SET_NEW_ORDER_STUDENTS, response.data.students);
                            scope.mountRevisionView = true
                        }
                        scope.isLoading = false;
                    }).catch((error) => {
                    console.error("error", error)
                    scope.isLoading = false;
                })
                return true
            },
            stepTwo() {
                let scope = this
                let requestData = {
                    training_id: this.newOrderData.training_id,
                    enterprise_id: this.newOrderData.enterprise_id,
                    start_date: this.newOrderData.start_date,
                    end_date: this.newOrderData.end_date,
                    welcome_message: this.newOrderData.welcome_message,
                    bye_message: this.newOrderData.bye_message,
                    students: this.newOrderData.students,
                    minimal_choice: this.newOrderData.minimal_choice,
                    send_welcome_card: this.newOrderData.send_welcome_card
                }
                this.mountRevisionView = false
                this.mountDetailsView = false
                this.isLoading = true;
                this.$store
                    .dispatch(ORDERS_CHECK_DATA, requestData)
                    .then((response) => {
                        if (response.status === false) {
                            if (response.go_back === true) {
                                scope.$bvToast.toast(
                                    JSON.stringify(response.errors),
                                    {
                                        title: response.message,
                                        variant: "danger",
                                        solid: true,
                                    }
                                );

                                if (response.students !== null) {
                                    scope.$store.commit(ORDERS_SET_NEW_ORDER_STUDENTS, response.students);
                                    scope.mountRevisionView = true
                                }

                                scope.doNotCheckAgain = true

                                scope.$refs.wizard.maxStep = 1
                                scope.$refs.wizard.tabs[1].checked = false
                                scope.$refs.wizard.navigateToTab(1)
                            }
                        }else{
                            scope.mountDetailsView = true
                        }
                        scope.isLoading = false;
                    });
                return true;
            },
            onComplete() {
                let scope = this
                let requestData = {
                    training_id: this.newOrderData.training_id,
                    enterprise_id: this.newOrderData.enterprise_id,
                    start_date: this.newOrderData.start_date,
                    end_date: this.newOrderData.end_date,
                    welcome_message: this.newOrderData.welcome_message,
                    bye_message: this.newOrderData.bye_message,
                    students: this.newOrderData.students,
                    enterprise: this.enterprise,
                    minimal_choice: this.newOrderData.minimal_choice,
                    send_welcome_card: this.newOrderData.send_welcome_card
                }
                this.isLoading = true;
                this.$store
                    .dispatch(ORDERS_STORE_ORDER, requestData)
                    .then((response) => {
                        if(response.status === true) {
                            scope.isLoading = false;

                            scope.$bvToast.toast(
                                response.message,
                                {
                                    title: "Orden",
                                    variant: "success",
                                    solid: true,
                                }
                            );
                            scope.$router.push("orders");
                        }else{
                            scope.isLoading = false;

                            scope.$bvToast.toast(
                                response.message,
                                {
                                    title: "Orden",
                                    variant: "warning",
                                    solid: true,
                                }
                            );
                        }
                    }).catch(() => {
                    scope.$bvToast.toast(
                        "El servidor devolvio un error.",
                        {
                            title: "Orden",
                            variant: "danger",
                            solid: true,
                        }
                    );
                });
            },
        },
        computed: {
            newOrderData: {
                get() {
                    return this.$store.state.orders.newOrderData;
                },
                set(value) {
                    this.$store.commit(ORDERS_SET_NEW_ORDER_DATA, value);
                },
            },
            enterprise() {
                return this.$store.state.orders.orderEnterpriseListExt.find((item) => {
                    return item.id_empresa === this.newOrderData.enterprise_id;
                });
            },
        },
        watch:{
            activeTabIndex(newValue, oldValue){
                if(newValue === 1 && oldValue === 2){
                    this.stepOne()
                }
            },
        }
    };
</script>
