<template>
    <div>
        <b-alert
                show
                variant="light"
                class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
            <div class="alert-text">
                Por favor, virifica que la información sea correcta. De no serlo realiza
                las correcciones pertinentes
            </div>
        </b-alert>
        <div class="row">
            <div class="col-md-6">
                <b-form-group id="input-group-3" label="Training" label-for="input-3">
                    <b-form-select
                            id="input-1"
                            v-model="newOrderData.training_id"
                            :options="trainings"
                            v-if="editingValues.training"
                            required
                    ></b-form-select>
                    <b v-else-if="training">{{ training.name }}</b>
                    <b v-else>No seleccionado</b>
                    <span
                            v-if="!editingValues.training"
                            @click="updateEditingValues('training')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group id="input-group-3" label="Empresa" label-for="input-3">
                    <b-form-select
                            id="input-1"
                            v-model="newOrderData.enterprise_id"
                            :options="enterprises"
                            v-if="editingValues.enterprise"
                            required
                    ></b-form-select>
                    <b v-else-if="enterprise">{{ enterprise.razon_social_empresa }}</b>
                    <b v-else>No seleccionado</b>
                    <span
                            v-if="!editingValues.enterprise"
                            @click="updateEditingValues('enterprise')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Fecha de inicio"
                        label-for="input-3"
                >
                    <b-form-datepicker
                            id="datepicker-star"
                            v-model="newOrderData.start_date"
                            v-bind="labels[locale] || {}"
                            :locale="locale"
                            class="mb-2"
                            v-if="editingValues.start_date"
                    >
                    </b-form-datepicker>
                    <b v-else>{{ newOrderData.start_date | formatDate}}</b>
                    <span
                            v-if="!editingValues.start_date"
                            @click="updateEditingValues('start_date')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                        id="revision-end_date-group"
                        label="Fecha de finalización"
                        label-for="input-3"
                >
                    <b-form-datepicker
                            id="revision-end_date-field"
                            v-model="newOrderData.end_date"
                            v-bind="labels[locale] || {}"
                            :locale="locale"
                            class="mb-2"
                            v-if="editingValues.end_date"
                    >
                    </b-form-datepicker>
                    <b v-else>{{ newOrderData.end_date | formatDate }}</b>
                    <span
                            v-if="!editingValues.end_date"
                            @click="updateEditingValues('end_date')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-form-group id="input-group-3"
                              label="Mínima cantidad obligatoria de cursos a seleccionar"
                              label-for="input-3">
                    <b-form-select
                            id="input-1"
                            v-model="newOrderData.minimal_choice"
                            :options="minimalChoiceList"
                            v-if="editingValues.minimal_choice"
                            required
                    ></b-form-select>
                    <b v-else-if="newOrderData.minimal_choice">{{ newOrderData.minimal_choice }}</b>
                    <b v-else>No seleccionado</b>
                    <span
                            v-if="!editingValues.minimal_choice"
                            @click="updateEditingValues('minimal_choice')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group id="input-send-welcome-card"
                              label="Enviar carta de presentación"
                              label-for="input-3">
                    <b-form-checkbox
                            v-model="newOrderData.send_welcome_card"
                            :value="1"
                            :unchecked-value="0"
                            v-if="editingValues.send_welcome_card"
                            size="lg"
                    >

                    </b-form-checkbox>
                    <b v-else>{{ newOrderData.send_welcome_card == 1? 'SI' : 'NO' }}</b>
                    <span
                            v-if="!editingValues.send_welcome_card"
                            @click="updateEditingValues('send_welcome_card')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
                    </span>
                </b-form-group>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Mensaje de bienvenida"
                        label-for="input-3"
                >
                    <b-form-textarea
                            id="form-welcome_message-field"
                            v-model="newOrderData.welcome_message"
                            placeholder="Escribe algo..."
                            rows="1"
                            max-rows="6"
                            v-if="editingValues.welcome_message"
                    ></b-form-textarea>
                    <b v-else>{{ newOrderData.welcome_message }}</b>
                    <span
                            v-if="!editingValues.welcome_message"
                            @click="updateEditingValues('welcome_message')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Mensaje de despedida"
                        label-for="input-3"
                >
                    <b-form-textarea
                            id="form-bye_message-field"
                            v-model="newOrderData.bye_message"
                            placeholder="Escribe algo..."
                            rows="1"
                            max-rows="6"
                            v-if="editingValues.bye_message"
                    ></b-form-textarea>
                    <b v-else>{{ newOrderData.bye_message }}</b>
                    <span
                            v-if="!editingValues.bye_message"
                            @click="updateEditingValues('bye_message')"
                            class="svg-icon svg-icon-md svg-icon-primary ml-1"
                    >
            <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
            ></inline-svg>
          </span>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <b-form-group class="col-md-12">
                <v-text-field
                        v-model="dtSearch"
                        label="Buscar"
                        append-icon="search"
                        class="mx-4"
                        single-line
                        hide-details
                ></v-text-field>
                <v-data-table
                        :headers="headers"
                        :items="students"
                        :search="dtSearch"
                        :loading="isLoadingTable"
                        :footer-props="{
                                itemsPerPageOptions: [100, 200, 300, 500, 100],
                                showFirstLastPage: true,
                                itemsPerPageText: 'Resultados por página'
                            }"
                        :items-per-page="itemsPerPage"
                        class="elevation-1 mb-5"
                        locale="es"
                >
                    <template v-slot:header.rut="{ header }">
                        <b class="custom-header-table"> RUT </b>
                    </template>
                    <template v-slot:header.dv="{ header }">
                        <b class="custom-header-table"> DV </b>
                    </template>
                    <template v-slot:header.name="{ header }">
                        <b class="custom-header-table"> Nombre </b>
                    </template>
                    <template v-slot:header.last_name="{ header }">
                        <b class="custom-header-table"> Apellido </b>
                    </template>
                    <template v-slot:header.email="{ header }">
                        <b class="custom-header-table"> Correo Empresa </b>
                    </template>
                    <template v-slot:header.phone="{ header }">
                        <b class="custom-header-table"> Teléfono Contacto </b>
                    </template>
                    <template v-slot:item.rut="{ item }">
                        <table-item
                                :alerts="item.alerts.rut"
                                :errors="item.errors.rut"
                                :rut="String(item.rut)"
                                :value="String(item.rut)"
                                field="rut"
                        ></table-item>
                    </template>
                    <template v-slot:item.dv="{ item }">
                        <table-item
                                :alerts="item.alerts.dv"
                                :errors="item.errors.dv"
                                :rut="String(item.rut)"
                                :value="String(item.dv)"
                                field="dv"
                        ></table-item>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <table-item
                                :alerts="item.alerts.name"
                                :errors="item.errors.name"
                                :rut="String(item.rut)"
                                :value="item.name"
                                field="name"
                        ></table-item>
                    </template>
                    <template v-slot:item.last_name="{ item }">
                        <table-item
                                :alerts="item.alerts.last_name"
                                :errors="item.errors.last_name"
                                :rut="String(item.rut)"
                                :value="item.last_name"
                                field="last_name"
                        ></table-item>
                    </template>
                    <template v-slot:item.email="{ item }">
                        <table-item
                                :alerts="item.alerts.email"
                                :errors="item.errors.email"
                                :rut="String(item.rut)"
                                :value="item.email"
                                field="email"
                        ></table-item>
                    </template>
                    <template v-slot:item.phone="{ item }">
                        <table-item
                                :alerts="item.alerts.phone"
                                :errors="item.errors.phone"
                                :rut="String(item.rut)"
                                :value="String(item.phone)"
                                field="phone"
                        ></table-item>
                    </template>
                    <template slot="no-data"> Ningún dato para mostrar</template>
                </v-data-table>
            </b-form-group>
        </div>
    </div>
</template>

<script>
    import {SET_NEW_ORDER_DATA, ORDERS_SET_NEW_ORDER_STUDENTS} from "@/store/modules/orders.module";
    import TableItem from "./TableItem";

    export default {
        name: "NewOrderRevision",
        components: {TableItem},
        data() {
            return {
                isLoading: false,
                isLoadingTable: false,
                options: {},
                currentPage: 1,
                itemsPerPage: 100,
                editingValues: {
                    training: false,
                    enterprise: false,
                    start_date: false,
                    end_date: false,
                    minimal_choice: false,
                    welcome_message: false,
                    bye_message: false,
                    comments: false,
                    send_welcome_card: false,
                },
                headers: [
                    {text: "RUT", value: "rut", align: "start", sortable: false},
                    {text: "DV", value: "dv", align: "start", sortable: false},
                    {text: "Nombre", value: "name", sortable: false},
                    {text: "Apellido", value: "last_name", align: "start", sortable: false},
                    {text: "Correo empresa", value: "email", align: "start", sortable: false},
                    {text: "Teléfono empresa", value: "phone", sortable: false},
                ],
                locale: "es-ES",
                locales: [{value: "es-ES", text: "Español ES (es-ES)"}],
                labels: {
                    "es-ES": {
                        labelPrevDecade: "Década anterior",
                        labelPrevYear: "Año anterior",
                        labelPrevMonth: "El mes pasado",
                        labelCurrentMonth: "Mes actual",
                        labelNextMonth: "Próximo mes",
                        labelNextYear: "El próximo año",
                        labelNextDecade: "La próxima década",
                        labelToday: "hoy dia",
                        labelSelected: "Fecha seleccionada",
                        labelNoDateSelected: "Sin fecha elegida",
                        labelCalendar: "Calendario",
                        labelNav: "Navegación de calendario",
                        labelHelp:
                            "Utilice las teclas de flecha para navegar por el calendario",
                    },
                },
                dtSearch: ""
            };
        },
        methods: {
            updateEditingValues(field) {
                this.editingValues[field] = !this.editingValues[field];
            },
        },
        mounted() {
        },
        computed: {
            newOrderData: {
                get() {
                    return this.$store.state.orders.newOrderData;
                },
                set(value) {
                    this.$store.commit(SET_NEW_ORDER_DATA, value);
                },
            },
            students: {
                get() {
                    return this.$store.state.orders.newOrderData.students;
                },
                set(value) {
                    this.$store.commit(ORDERS_SET_NEW_ORDER_STUDENTS, value);
                },
            },
            totalRows() {
                if (this.newOrderData.students)
                    return this.newOrderData.students.length;
                return 0
            },
            enterprise() {
                return this.$store.state.orders.orderEnterpriseListExt.find((item) => {
                    return item.id_empresa === this.newOrderData.enterprise_id;
                });
            },
            enterprises() {
                return this.$store.state.orders.orderEnterpriseListExt.map((item) => {
                    return { text: item.razon_social_empresa, value: item.id_empresa };
                });
            },
            training() {
                return this.$store.state.orders.orderTrainingList.find((item) => {
                    return item.id === this.newOrderData.training_id;
                });
            },
            trainings() {
                return this.$store.state.orders.orderTrainingList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
            minimalChoiceList() {
                return [1, 2, 3, 4, 5]
            },
        },
    };
</script>
