<template>
    <div>
        <div class="flex-inline">
            <b-form-input
                    id="form-value-field"
                    v-model="textValue"
                    rows="1"
                    v-if="editing"
            ></b-form-input>
            <span
                    v-if="editing"
                    @click="updateValue()"
                    class="svg-icon svg-icon-md svg-icon-primary ml-1"
            >
                  <inline-svg src="media/svg/icons/Code/Done-circle.svg"></inline-svg>
                </span>
        </div>
        <div
                v-if="!editing"
                v-bind:id="'tooltip-target-' + field + '-' + rut"
                class="table-value"
                v-bind:class="{
        'content-alerts': hasAlerts,
        'content-errors': hasErrors,
      }"
        >
            {{ textValue }}
        </div>
        <b-tooltip
                v-if="errors.length > 0 || alerts.length > 0"
                v-bind:target="'tooltip-target-' + field + '-' + rut"
                triggers="hover"
                :variant="errors.length > 0 ? 'danger' : 'warning'"
        >
            <ul>
                <div v-for="alert in alerts" :key="alert">
                    <li>{{ alert }}</li>
                </div>
                <div v-for="error in errors" :key="error">
                    <li>{{ error }}</li>
                </div>
            </ul>
        </b-tooltip>
        <span
                v-if="!editing"
                @click="updateEditingValues()"
                class="svg-icon svg-icon-md svg-icon-primary ml-1"
        >
      <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
    </span>
    </div>
</template>

<script>
    import {
        ORDERS_SET_VALUE_FOR_STUDENT_IN_LIST
    } from "@/store/modules/orders.module";
    export default {
        name: "TableItem",
        props: {
            alerts: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            errors: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            rut: {
                type: String,
                default: "",
            },
            field: {
                type: String,
                default: "",
            },
            value: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                editing: false,
                hasErrors: this.errors.length > 0,
                hasAlerts: this.alerts.length > 0,
            }
        },
    mounted() {
    }
        ,
        computed: {
            textValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.value = value;
                },
            },
        },
        methods: {
            updateEditingValues() {
                this.editing = !this.editing
            },
            updateValue() {
                this.hasErrors = false
                this.hasAlerts = false
                this.editing = !this.editing
                this.$store.commit(ORDERS_SET_VALUE_FOR_STUDENT_IN_LIST, {
                    rut: this.rut,
                    field: this.field,
                    value: this.textValue,
                })
                // this.$emit("TableItemUpdated");
            },
        },
        watch:{
            errors(newValue, oldValue){
                this.hasErrors = newValue.length > 0
            },
            alerts(newValue, oldValue){
                this.hasAlerts = newValue.length > 0
            }
        }
    };
</script>

<style scoped></style>
