<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Files/DownloadedFile.svg" />
        </span>
      </div>
      <div class="alert-text">
        Descarga el <b>formato de ejemplo</b> de la planilla de solicitud
        <a
          class="font-weight-bold"
          href="https://blobcert.blob.core.windows.net/training/Planilla_ejemplo.xlsx"
          target="_blank"
        >
          aquí
        </a>
      </div>
    </b-alert>
    <div>
      <b-form>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              id="form-training-group"
              label="Training"
              label-for="input-1"
            >
              <b-form-select
                id="form-training-field"
                v-model="newOrderData.training_id"
                :options="trainings"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="form-company-group"
              label="Empresa"
              label-for="input-2"
            >
              <b-form-select
                      id="form-enterprise-field"
                      v-model="newOrderData.enterprise_id"
                      :options="enterprises"
                      required
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              id="form-start_date-group"
              label="Fecha de inicio"
              label-for="input-3"
            >
              <b-form-datepicker
                id="form-start_date-field"
                v-model="newOrderData.start_date"
                v-bind="labels[locale] || {}"
                :locale="locale"
                class="mb-2"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="form-end_date-group"
              label="Fecha de finalización"
              label-for="input-4"
            >
              <b-form-datepicker
                id="form-end_date-field"
                v-model="newOrderData.end_date"
                v-bind="labels[locale] || {}"
                :locale="locale"
                class="mb-2"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="input-group-3"
                          label="Mínima cantidad obligatoria de cursos a seleccionar"
                          label-for="input-3">
              <b-form-select
                      id="input-1"
                      v-model="newOrderData.minimal_choice"
                      :options="minimalChoiceList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="input-send-welcome-card"
                          label="Enviar carta de presentación"
                          label-for="input-3">
              <b-form-checkbox
                               v-model="newOrderData.send_welcome_card"
                               value="1"
                               unchecked-value="0"
                               size="lg"
              >

              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row d-none">
          <div class="col-md-6">
            <b-form-group
              id="form-welcome_message-group"
              label="Mensaje de bienvenida"
              label-for="input-7"
            >
              <b-form-textarea
                id="form-welcome_message-field"
                v-model="newOrderData.welcome_message"
                placeholder="Escribe algo..."
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="form-bye_message-group"
              label="Mensaje de despedida"
              label-for="input-7"
            >
              <b-form-textarea
                id="form-bye_message-field"
                v-model="newOrderData.bye_message"
                placeholder="Escribe algo..."
                rows="1"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              id="form-file-group"
              label="Listado de alumnos"
              label-for="input-5"
            >
              <b-form-file
                id="form-file-field"
                v-model="newOrderData.file"
                placeholder="Selecciona un archivo o arrastralo hasta aquí..."
                drop-placeholder="Arrastra hasta aquí..."
              ></b-form-file>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ORDERS_SET_NEW_ORDER_DATA } from "../../../store/modules/orders.module";

export default {
  name: "NewOrderForm",
  data() {
    return {
      locale: "es-ES",
      locales: [{ value: "es-ES", text: "Español ES (es-ES)" }],
      labels: {
        "es-ES": {
          labelPrevDecade: "Década anterior",
          labelPrevYear: "Año anterior",
          labelPrevMonth: "El mes pasado",
          labelCurrentMonth: "Mes actual",
          labelNextMonth: "Próximo mes",
          labelNextYear: "El próximo año",
          labelNextDecade: "La próxima década",
          labelToday: "hoy dia",
          labelSelected: "Fecha seleccionada",
          labelNoDateSelected: "Sin fecha elegida",
          labelCalendar: "Calendario",
          labelNav: "Navegación de calendario",
          labelHelp:
            "Utilice las teclas de flecha para navegar por el calendario",
        },
      },
    };
  },
  methods: {
  },
  computed: {
    newOrderData: {
      get() {
        console.log('newOrderData',this.$store.state.orders.newOrderData)
        return this.$store.state.orders.newOrderData;
      },
      set(value) {
        this.$store.commit(ORDERS_SET_NEW_ORDER_DATA, value);
      },
    },
    enterprises() {
      return this.$store.state.orders.orderEnterpriseListExt.map((item) => {
        return { text: item.razon_social_empresa, value: item.id_empresa };
      });
    },
    trainings() {
      return this.$store.state.orders.orderTrainingList.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    minimalChoiceList() {
      if(this.newOrderData.training_id != null){
        let training = this.$store.state.orders.orderTrainingList.find((training) => {
          return training.id == this.newOrderData.training_id
        })
        if(training != null){
          let arrNumbers = [];
          for (let it = 1; it <= training.courses.length; it++){
            arrNumbers.push(it)
          }
          return arrNumbers
        }
      }
      return [0]
    },
  },
};
</script>
<style src="vue-select/dist/vue-select.css"></style>
