<template>
    <div>
        <b-alert
                show
                variant="info"
                class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
            <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Navigation/Check.svg"/>
        </span>
            </div>
            <div class="alert-text">
                Revise cuidadosamente los datos de la orden para ser aceptados. Puede retroceder a la revision de datos
                para cambios de ultima hora.
            </div>
        </b-alert>
        <div class="row">
            <div class="col-md-6">
                <b-form-group id="input-group-3" label="Training" label-for="input-3">
                    <b v-if="training">{{ training.name }}</b>
                    <b v-else>No seleccionado</b>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group id="input-group-3" label="Empresa" label-for="input-3">
                    <b v-if="enterprise">{{ enterprise.razon_social_empresa }}</b>
                    <b v-else>No seleccionado</b>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Fecha de inicio"
                        label-for="input-3"
                >
                    <b>{{ newOrderData.start_date | formatDate}}</b>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                        id="revision-end_date-group"
                        label="Fecha de finalización"
                        label-for="input-3"
                >
                    <b>{{ newOrderData.end_date | formatDate}}</b>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Mínima cantidad obligatoria de cursos a seleccionar"
                        label-for="input-3"
                >
                    <b>{{ newOrderData.minimal_choice }}</b>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Mensaje de bienvenida"
                        label-for="input-3"
                >
                    <b>{{ newOrderData.welcome_message }}</b>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                        id="input-group-3"
                        label="Mensaje de despedida"
                        label-for="input-3"
                >
                    <b>{{ newOrderData.bye_message }}</b>
                </b-form-group>
            </div>
        </div>
        <div class="row" data-app>
            <div class="col-md-12">
                <v-text-field
                        v-model="dtSearch"
                        label="Buscar"
                        append-icon="search"
                        class="mx-4"
                        single-line
                        hide-details
                ></v-text-field>
                <v-data-table
                        :headers="headers"
                        :items="newOrderData.students"
                        :loading="isLoadingTable"
                        :footer-props="{
                            itemsPerPageOptions: [5, 10, 25, 50],
                            showFirstLastPage: true,
                            itemsPerPageText: 'Resultados por página'
                        }"
                        class="elevation-1 mb-5"
                        locale="es"
                        :search="dtSearch"
                >
                    <template v-slot:header.rut="{ header }">
                        <b class="custom-header-table"> RUT </b>
                    </template>
                    <template v-slot:header.dv="{ header }">
                        <b class="custom-header-table"> DV </b>
                    </template>
                    <template v-slot:header.name="{ header }">
                        <b class="custom-header-table"> Nombre </b>
                    </template>
                    <template v-slot:header.last_name="{ header }">
                        <b class="custom-header-table"> Apellido </b>
                    </template>
                    <template v-slot:header.email="{ header }">
                        <b class="custom-header-table"> Correo </b>
                    </template>
                    <template v-slot:header.phone="{ header }">
                        <b class="custom-header-table"> Teléfono </b>
                    </template>
                    <template slot="no-data"> Ningún dato para mostrar</template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_NEW_ORDER_DATA} from "../../../store/modules/orders.module";

    export default {
        name: "NewOrderDetail",
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 10,
                isLoading: false,
                isLoadingTable: false,
                dtSearch: "",
                editingValues: {
                    training: false,
                    enterprise: false,
                    start_date: false,
                    end_date: false,
                    welcome_message: false,
                    bye_message: false,
                    comments: false,
                },
                headers: [
                    {text: "RUT", value: "rut", align: " d-none", sortable: false},
                    {text: "DV", value: "dv", align: " d-none", sortable: false},
                    {text: "RUT", value: "fullRutWithHypend", align: "start", sortable: false},
                    {text: "Nombre", value: "name", sortable: false},
                    {text: "Apellido", value: "last_name", align: "start", sortable: false},
                    {text: "Correo empresa", value: "email", align: "start", sortable: false},
                    {text: "Teléfono empresa", value: "phone", sortable: false},
                ],
                locale: "es-ES",
                locales: [{value: "es-ES", text: "Español ES (es-ES)"}],
                labels: {
                    "es-ES": {
                        labelPrevDecade: "Década anterior",
                        labelPrevYear: "Año anterior",
                        labelPrevMonth: "El mes pasado",
                        labelCurrentMonth: "Mes actual",
                        labelNextMonth: "Próximo mes",
                        labelNextYear: "El próximo año",
                        labelNextDecade: "La próxima década",
                        labelToday: "hoy dia",
                        labelSelected: "Fecha seleccionada",
                        labelNoDateSelected: "Sin fecha elegida",
                        labelCalendar: "Calendario",
                        labelNav: "Navegación de calendario",
                        labelHelp:
                            "Utilice las teclas de flecha para navegar por el calendario",
                    },
                },
            };
        },
        methods: {
            updateEditingValues(field) {
                this.editingValues[field] = !this.editingValues[field];
            },
        },
        computed: {
            newOrderData: {
                get() {
                    return this.$store.state.orders.newOrderData;
                },
                set(value) {
                    this.$store.commit(SET_NEW_ORDER_DATA, value);
                },
            },
            enterprise() {
                return this.$store.state.orders.orderEnterpriseListExt.find((item) => {
                    return item.id_empresa === this.newOrderData.enterprise_id;
                });
            },
            training() {
                return this.$store.state.orders.orderTrainingList.find((item) => {
                    return item.id === this.newOrderData.training_id;
                });
            },
            totalRows() {
                return this.$store.state.courses.coursesListTotal;
            },
            list() {
                return this.$store.state.courses.coursesList;
            },
            trainings() {
                return this.$store.state.trainings.trainingsSimpleList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
        },
    };
</script>

<style scoped></style>
